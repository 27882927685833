<template>
  <div>
    <canvas
      ref="canvas_visible"
      :width="video_width"
      :height="video_height"
      :style="{
        position: 'fixed',
        'pointer-events': 'none',
        right: 0,
        top: '15vh',
        zIndex: 10
      }"
    ></canvas>
    <div style="display: none">
      <video ref="video" :width="video_width" :height="video_height" playsinline muted>
        <source :src="video_src" />
      </video>
      <canvas ref="canvas_hidden" :width="video_width" :height="video_height"></canvas>
    </div>
  </div>
</template>

<script>
  import {TweenMax} from "gsap/all";

  export default {
    name: "TransparentVideo",
    props: ["video_src", "video_width", "video_height"],
    mounted() {
      const vm = this,
        video = vm.$refs.video,
        canvas_hidden = vm.$refs.canvas_hidden,
        canvas_visible = vm.$refs.canvas_visible;

      new TransparentVideoProcessor(video, canvas_hidden, canvas_visible, vm.video_width, vm.video_height)
      setTimeout(play, 2000)
      function play() {
        video.play();
        TweenMax.set(canvas_visible, {x: 0})
        TweenMax.to(canvas_visible, 8, {
          x: -(window.innerWidth-vm.video_width),
          delay: .5,
          ease: "slow(0.5, 1, false)",
          onComplete() {canvas_visible.style.display = "none"}
        })
      }
    }
  }

  function TransparentVideoProcessor(video, canvas_hidden, canvas_visible, width, height) {
    this.video = video;
    this.ctx1 = canvas_hidden.getContext("2d");
    this.ctx2 = canvas_visible.getContext("2d");
    this.width = width;
    this.height = height;
    this.timerCallback();
  }

  TransparentVideoProcessor.prototype.timerCallback = function() {
    if (this.video.ended) return;
    this.computeFrame();

    let self = this;
    setTimeout(function () {self.timerCallback();}, 0);
  }

  TransparentVideoProcessor.prototype.computeFrame = function() {
    this.ctx1.drawImage(this.video, 0, 0, this.width, this.height);
    let frame = this.ctx1.getImageData(0, 0, this.width, this.height);
    let l = frame.data.length / 4;

    for (let i = 0; i < l; i++) {
      let r = frame.data[i * 4 + 0];
      let g = frame.data[i * 4 + 1];
      let b = frame.data[i * 4 + 2];
      if (g > 100 && (g > (r+b)*.7))
      // if (g < 10 && r < 10 && b < 10)
          frame.data[i * 4 + 3] = 0;
    }
    this.ctx2.putImageData(frame, 0, 0);
  }

</script>

<style scoped>

</style>