<template>
  <div>
    <LandingPageHome
      :bg_url="'/static/images/GOT-dubrovnik.jpg'"
      bg_pos_y="0%"
    />
    <TransparentVideo
      :video_src="'/static/images/video_dragon_360.mp4'"
      :video_width="1920*video_scale"
      :video_height="1080*video_scale"
    />
  </div>
</template>

<script>
  import LandingPageHome from "./LandingPageHome"
  import TransparentVideo from "./TransparentVideo"
  export default {
    name: "LandingPageGoT",
    components: {TransparentVideo, LandingPageHome},
    data() {
      return {
        video_scale: Math.min(window.innerWidth/1920, .41)
      }
    }
  }
</script>

<style scoped>

</style>